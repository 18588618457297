/* Theme color vars */
@accentColor: rgba(255, 204, 2, 1);
@accentColor80: rgba(255, 204, 2, 0.8);
@accentColor70: rgba(255, 204, 2, 0.7);
@accentColor50: rgba(255, 204, 2, 0.5);
@accentColor40: rgba(255, 204, 2, 0.4);
@accentColor30: rgba(255, 204, 2, 0.3);
@accentColor20: rgba(255, 204, 2, 0.2);
@accentColor10: rgba(255, 204, 2, 0.1);
@accentColor075: rgba(255, 204, 2, 0.075);
@accentColor5: rgba(255, 204, 2, 0.05);
@accentColor3: rgba(255, 204, 2, 0.03);
@accentColor70Solid: #FFDC4E;
@accentColor10Solid: #FFFAE6;
@accentColor5Solid: #FFFCF2;
@accentHEX: #FFCC02;
@buttonAccentColor: #FFCC02;

:root {
  // Colors
  // Primary
  --color-primary-50: #FFF9E8;
  --color-primary-75: #FFEEBA;
  --color-primary-100: #FFE48C;
  --color-primary-200: #FFDB5E;
  --color-primary-300: #FFD330;
  --color-primary-400: #FFCC02;
  --color-primary-500: #FFB300;
  --color-primary-600: #FF9900;
  --color-primary-700: #D07D02;
  --color-primary-800: #A16003;
  --color-primary-900: #734503;
  --color-primary-1000: #462A02;
  --color-primary-1100: #462A02;
}

body {
  &.modern-theme {
    :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) {
      .formio-component-modal-wrapper .formio-component,
      &:not(.new-formio-part-b) .formio-form .formio-component,
      &:not(.new-formio-part-b) .formio-form .formio-dialog,
      &.new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component,
      &.new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component,
      .formio-dialog-content .formio-component {
        &.formio-component-button {
          button {
            --button-text-color: var(--color-gray-900);
            --button-text-color-hover: var(--color-gray-900);
          }
        }
        &.formio-component-checkbox .form-check.checkbox label.form-check-label input.form-check-input:checked + span::before {
          color: var(--color-primary-400) !important;
        }
        &.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label input[type="checkbox"]:checked::before {
          color: var(--color-primary-400);
        }
        &.formio-component-radio.radio-switch .form-radio.radio :where(.form-check-inline, .form-check) label.form-check-label input[type="radio"]:checked + span {
          background-color: var(--color-primary-400);
          color: var(--color-gray-900) !important;
          border: solid 1px var(--color-primary-400);
        }
        &.formio-component-editgrid {
          > button.btn:hover {
            background-color: var(--color-primary-400) !important;
            color: var(--color-gray-900);
          }
          ul.editgrid-listgroup li.list-group-item {
            &.list-group-header .row > div {
              color: var(--color-primary-400);
            }
            &.saved-entry .editRow {
              --button-border-color: var(--color-primary-400);
            }
          }
          .editgrid-actions .btn.btn-primary {
            background-color: var(--color-primary-400);
            color: var(--color-gray-900);
          }
        }
        a[href] {
          color: var(--color-primary-400);
        }
      }
    }
    #select-business-entity .modal-dialog {
      .panel {
        .panel-footer a {
          background-color: var(--color-primary-400);
          color: var(--color-gray-900);
        }
        .panel-body {
            .radio label input[type="radio"]:checked::before {
            content: "\f192";
            font-weight: 900;
            color: var(--color-primary-400);
          }
        }
      }
    }
    .formio-component-modal-wrapper .formio-component.formio-component-checkbox.check-button:not(.btn-blue, .btn-green, .btn-orange, .btn-red),
    .formio-form .formio-component.formio-component-checkbox.check-button:not(.btn-blue, .btn-green, .btn-orange, .btn-red) {
      --button-text-color: var(--color-gray-900);
      --button-text-color-hover: var(--color-gray-900);
    }
  }
}