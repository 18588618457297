@import "./themes/default/_themes-colors.less";

@roundedCorners: 6px;

:root[dir="rtl"] {
    --main-font-family: "Noto Sans Arabic", sans-serif;
}

html {
    &[dir="rtl"] {
        --corner-top-end-radius: 6px 0 0 0;
        --main-font-family: "Noto Sans Arabic", sans-serif;
        
        /* Modern Theme */

        body {
            &.modern-theme {
                @panelBodyPadding: 24px;

                // Overrides
                .radio label, .checkbox label {
                    padding-left: initial;
                    padding-inline-start: 20px;
                }
                :where(.radio, .radio-inline, .checkbox, .checkbox-inline) {
                    :where(input[type="radio"], input[type="checkbox"]) {
                        margin-left: initial;
                        margin-inline-start: -20px;
                    }
                }
                #my-account-page .formio-form,
                &.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form {
                    :is(.wizard-page .formio-component, .formio-component) {
                        &.force-ltr {
                            .input-group {
                                flex-direction: row-reverse;
                                .input-group-prepend {
                                    & + .form-control {
                                        border-inline-end: none !important;
                                        border-inline-start: solid 1px var(--fields-border-color, @borderColor) !important;
                                        text-align: end;
                                    }
                                }
                            }
                        }
                    }
                    :is(.wizard-page .formio-component, .formio-component):not(.force-ltr) {
                        &.formio-component-button:is(.field-plus-button) button.btn {
                            border-top-left-radius: @roundedCorners !important;
                            border-bottom-left-radius: @roundedCorners !important;
                            border-top-right-radius: 0 !important;
                            border-bottom-right-radius: 0 !important;
                        }
                        &.formio-component-select.field-plus-button .formio-choices .selection.dropdown {
                            border-radius: 0 @roundedCorners @roundedCorners 0 !important;
                            flex-direction: row;
                        }
                        &.formio-component-textarea.field-plus-button textarea.form-control {
                            border-top-right-radius: @roundedCorners !important;
                            border-top-left-radius: 0 !important;
                        }
                        :is(.fa-circle-arrow-right, .fa-circle-arrow-left) {
                            transform: rotateY(180deg);
                        }
                    }
                }
                .payment-fee .formio-component-htmlelement {
                    &:is([class*="price_"], [class*="total"]) p[ref="html"] {
                        direction: ltr;
                        display: flex;
                        justify-content: flex-start !important;
                    }
                }
                .regs-docs-fees-cart {
                    :where(.no-cost-msg, .fees) {
                        direction: ltr;
                    }
                    &.open {
                        :where(.no-cost-msg, .fees) {
                            padding-inline-start: 50px !important;
                            padding-inline-end: 10px !important;
                        }
                    }
                }
                .cart-entry {
                    .currency {
                        direction: ltr;
                        order: 2;

                        & + .reg-price {
                            order: 1;
                        }
                    }
                }
                .reg-price {
                    order: 3;
                }
                .drawer-section-header:has(.currency) {
                    .currency {
                        order: 3;
                        direction: ltr;
                        margin-inline-end: 6px;

                        &:nth-last-of-type(2) {
                            margin-inline-start: 16px;

                            & + span {
                                margin-inline-end: 6px;
                                direction: ltr;
                            }
                        }
                    }
                    span:not(.currency):nth-last-of-type(2) {
                        order: 4;
                        direction: ltr;
                        margin-inline-start: 16px;
                    }
                    i {
                        order: 5;
                    }
                }
                .formio-component-tabs > .card.card-vertical > .card-header a.nav-link.active:last-child {
                    border-radius: 6px 0 0 6px !important;
                    box-shadow: inset -3px 0 var(--wizard-page-link-active-bg-color, #222);
                }

                // RTL changes
                #reportrange {
                    float: left;
                }
                button.button.flying-button {
                    border-top-left-radius: 0 !important;
                    border-bottom-left-radius: 0 !important;
            
                    &.draft-save {
                        border-radius: 0 @roundedCorners @roundedCorners 0 !important;
                        // flex-direction: column;
                        // align-items: center;
                        // padding: 1rem 1.4rem 1rem 1.1rem;

                        &:hover {
                            box-shadow: 0px -6px 20px rgba(70, 81, 170, 0.4) !important;
                        }
                    }
                }
                :where(.modern-theme-dashboard-menu, .current-service-container) {
                    .current-service-text {
                        .fa-chevron-right {
                            transform: rotateY(180deg);
                        }
                    }
                }
                .modern-theme-be-list {
                    .contain-lg {
                        .dropdown.ml-auto {
                            .dropdown-menu {
                                border-radius: 0 @roundedCorners @roundedCorners @roundedCorners;
                            }
                        }
                    }
                }
                .management_bar {
                    .contain-lg {
                        > ul.nav-pills {
                            li.nav-item {
                                a.nav-link {
                                    display: flex;
                                    align-items: center;
                                }
                            }
                        }
                    }
                }
                #base {
                    #content {
                        .section-body {
                            .contain-lg {
                                .dashboard {
                                    #tab-applications {
                                        #applications-list {
                                            > table {
                                                thead {
                                                    tr {
                                                        th {
                                                            &:first-child span:not(.sortorder) {
                                                                border-radius: 0 @roundedCorners @roundedCorners 0;
                                                            }
                                                            &.opt-col::before {
                                                                border-radius: @roundedCorners 0 0 @roundedCorners;
                                                            }
                                                        }
                                                    }
                                                }
                                                tbody {
                                                    td {
                                                        &.app-status {
                                                            &::before {
                                                                border-radius: 2px 0 0 2px;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            .table-paging-container {
                                                ul.pagination {
                                                    li {
                                                        &:first-of-type,
                                                        &:last-of-type {
                                                            a {
                                                                &::before {
                                                                    transform: rotateZ(180deg);
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    #tab-documents {
                                        #documents-others {
                                            .alert {
                                                &::after {
                                                    inset-inline-start: initial !important;
                                                    inset-inline-end: 0;
                                                }
                                            }
                                            .table-paging-container {
                                                .docs-pagination {
                                                    ul.pagination {
                                                        li {
                                                            &:first-of-type,
                                                            &:last-of-type {
                                                                a {
                                                                    &::before {
                                                                        transform: rotateZ(180deg);
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    #tab-messages {
                                        table.table {
                                            tbody tr td {
                                                &.msg-status {
                                                    &::before {
                                                        border-radius: 2px 0 0 2px;
                                                    }
                                                }
                                            }
                                        }
                                        .table-paging-container {
                                            .msgs-pagination {
                                                ul.pagination {
                                                    li {
                                                        &:first-of-type,
                                                        &:last-of-type {
                                                            a {
                                                                &::before {
                                                                    transform: rotateZ(180deg);
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                .be-list-page {
                                    a.back-link {
                                        i.back-link-icon {
                                            transform: rotateZ(180deg);
                                            position: relative;
                                            top: -1px;
                                        }
                                    }
                                    .list-container {
                                        table.business-list-table {
                                            tbody {
                                                tr {
                                                    td {
                                                        &.row-toggler {
                                                            border-radius: 0 @roundedCorners @roundedCorners 0;
                                                        }
                                                    }
                                                    &.active {
                                                        td {
                                                            &:last-child {
                                                                border-radius: @roundedCorners 0 0 0;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                .manage-be-page {
                                    a.back-link {
                                        i.back-link-icon {
                                            transform: rotateZ(180deg);
                                            position: relative;
                                            top: -1px;
                                        }
                                    }
                                    table {
                                        &.users-list-table {
                                            tfoot {
                                                tr {
                                                    th {
                                                        .input-group {
                                                            input.form-control {
                                                                border-bottom-right-radius: @roundedCorners;
                                                                border-top-right-radius: @roundedCorners;
                                                                border-bottom-left-radius: 0;
                                                                border-top-left-radius: 0;
                                                            }
                                                            button.btn {
                                                                border-bottom-left-radius: @roundedCorners;
                                                                border-top-left-radius: @roundedCorners;
                                                                border-bottom-right-radius: 0;
                                                                border-top-right-radius: 0;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                #formio-form-wizard,
                                #formio-form-role {
                                    .formio-form {
                                        .formio-component:not(.force-ltr) {
                                            &-select {
                                                .choices__list {
                                                    direction: rtl;
                                                    padding: 0;
                                                }
                                            }
                                            &-radio {
                                                &:not(.radio-switch) {
                                                    .form-radio {
                                                        .form-check {
                                                            .form-check-label {
                                                                &:is(.label-position-right, .label-position-) {
                                                                    span {
                                                                        width: 100%;
                                                                        display: block;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                                &.radio-switch {
                                                    .form-radio {
                                                        &.radio {
                                                            .radio {
                                                                &:first-child {
                                                                    label {
                                                                        &.form-check-label {
                                                                            span {
                                                                                border-radius: 0 @roundedCorners @roundedCorners 0 !important;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                                &:last-child {
                                                                    label {
                                                                        &.form-check-label {
                                                                            span {
                                                                                border-radius: @roundedCorners 0 0 @roundedCorners !important;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            &-editgrid {
                                                > button.btn {
                                                    display: flex !important;
                                                }
                                            }
                                            &-datagrid {
                                                > table.table.datagrid-table {
                                                    thead tr th {
                                                        &:first-child {
                                                            border-radius: 0 @roundedCorners @roundedCorners 0 !important;
                                                        }
                                                        &:last-child {
                                                            border-radius: @roundedCorners 0 0 @roundedCorners !important;
                                                        }
                                                    }
                                                }
                                            }
                                            &-htmlelement {
                                                &.alert.alert-danger {
                                                    border-radius: @roundedCorners 0 0 @roundedCorners !important;
                                                }
                                            }
                                            &-panel {
                                                > .card {
                                                    > .card-header {
                                                        .card-title {
                                                            i.fa-question-circle {
                                                                position: relative;
                                                                display: flex;
                                                            }
                                                        }
                                                    }
                                                }
                                                &:is(.formio-component-FeesPanel, .formio-component-requiredDocumentsPanel) {
                                                    .card {
                                                        .card-body {
                                                            [class*="formio-component-priceRow"] {
                                                                &:not(.formio-hidden) {
                                                                    display: flex;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                                &.notice {
                                                    > .card {
                                                        border-radius: @roundedCorners 0 0 @roundedCorners !important;
                                    
                                                        > .card-header {
                                                            border-radius: @roundedCorners 0 0 0;
                                                        }
                                                        > .card-body {
                                                            border-radius: 0 0 0 @roundedCorners;

                                                            &:only-child {
                                                                border-radius: @roundedCorners 0 0 @roundedCorners;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            &-columns {
                                                &.payment-fee {
                                                    display: flex !important;
                                                }
                                            }
                                            &.formio-component-textfield {
                                                .input-group.formio-multiple-mask-container {
                                                    .formio-multiple-mask-select {
                                                        border-radius: 0 @roundedCorners @roundedCorners 0 !important;
                                                        background-position: left 12px center;

                                                        & + .form-control.formio-multiple-mask-input {
                                                            border-radius: @roundedCorners 0 0 @roundedCorners !important;
                                                        }
                                                    }
                                                }
                                            }
                                            &:is(.notice, .info-notice, .success-notice, .warning-notice, .error-notice) {
                                                --notice-corner-radius: @roundedCorners 0 0 @roundedCorners;

                                                &.fill-background {
                                                    --notice-corner-radius: @roundedCorners 0 0 @roundedCorners;
                                                }
                                            }
                                            &:is(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address) {
                                                &.field-plus-button {
                                                    input {
                                                        &.form-control {
                                                            &:is([type="text"], [type="phoneNumber"], [type="email"]) {
                                                                border-top-right-radius: @roundedCorners !important;
                                                                border-bottom-right-radius: @roundedCorners !important;
                                                                border-top-left-radius: 0 !important;
                                                                border-bottom-left-radius: 0 !important;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            &.formio-component-number {
                                                .form-control {
                                                    text-align: end;
                                                }
                                            }
                                        }
                                        .wizard-page {
                                            .guide-form {
                                                > div[class^="col-md-4"]::before {
                                                    content: "\F104";
                                                }
                                            }
                                            .formio-component:not(.force-ltr) {
                                                &.formio-component-number {
                                                    [ref="input"] {
                                                        direction: ltr;
                                                    }
                                                    &.required {
                                                        &.formio-component-label-hidden {
                                                            .input-group-append {
                                                                position: initial;
                                                            }
                                                            &.has-suffix {
                                                                &:not(.has-prefix) {
                                                                    .input-group-append {
                                                                        position: initial;
                                                                        border-radius: @roundedCorners 0px 0px @roundedCorners !important;

                                                                        &::before {
                                                                            inset-inline-start: 10px;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                            &.has-prefix {
                                                                &:not(.has-suffix) {
                                                                    label.field-required.control-label--hidden {
                                                                        &::after {
                                                                            display: none;
                                                                        }
                                                                    }
                                                                    .input-group {
                                                                        position: relative;

                                                                        &::before {
                                                                            content: " ";
                                                                            display: inline-block;
                                                                            background-color: @errorColor70;
                                                                            vertical-align: unset;
                                                                            width: 6px;
                                                                            height: 6px;
                                                                            border-radius: 5px;
                                                                            position: absolute;
                                                                            top: calc(50% - 3px);
                                                                            inset-inline-end: 10px;
                                                                            min-width: 6px;
                                                                            z-index: 9;
                                                                        }
                                                                    }
                                                                }
                                                                &.has-suffix {
                                                                    .input-group-append {
                                                                        &::before {
                                                                            display: none;
                                                                        }
                                                                    }
                                                                    .input-group-prepend {
                                                                        position: relative;
                                                                        
                                                                        &::before {
                                                                            content: " ";
                                                                            display: inline-block;
                                                                            background-color: @errorColor70;
                                                                            vertical-align: unset;
                                                                            width: 6px;
                                                                            height: 6px;
                                                                            border-radius: 5px;
                                                                            position: absolute;
                                                                            top: calc(50% - 3px);
                                                                            inset-inline-end: -16px;
                                                                            min-width: 6px;
                                                                            z-index: 9;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                                &.formio-component-datetime {
                                                    .input-group {
                                                        input.form-control.input {
                                                            border-radius: 0 @roundedCorners @roundedCorners 0 !important;
                                                        }
                                                        .input-group-append {
                                                            border-radius: @roundedCorners 0 0 @roundedCorners !important;

                                                            .input-group-text {
                                                                border-radius: @roundedCorners 0 0 @roundedCorners;
                                                            }
                                                        }
                                                    }
                                                }
                                                &.formio-component-htmlelement {
                                                    &.alert.alert-danger {
                                                        border-radius: @roundedCorners 0 0 @roundedCorners !important;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    .tabbed-modern-nav > .formio-form > div > nav .pagination li.page-item.active {
                                        inset-inline: auto;
                                    }
                                }
                                :is(.dg-action-col, th:last-child) {
                                    --cell-heading-border-radius: @roundedCorners 0 0 @roundedCorners;
                                }
                                th:first-child {
                                    --cell-heading-border-radius: 0 @roundedCorners @roundedCorners 0;
                                }
                                .tab-pane {
                                    &.active {
                                        --tab-pane-border-radius: @roundedCorners 0 @roundedCorners @roundedCorners;
                                    }
                                }
                                .radio-switch {
                                    :where(.form-check-inline, .form-check) {
                                        &:first-child {
                                            label {
                                                &.form-check-label {
                                                    span {
                                                        --radio-switch-first-border-radius: 0 @roundedCorners @roundedCorners 0;
                                                    }
                                                }
                                            }
                                          }
                                          &:last-child {
                                            label {
                                                &.form-check-label {
                                                    span {
                                                        --radio-switch-last-border-radius: @roundedCorners 0 0 @roundedCorners;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            #entreprise-grid {
                                .table-responsive-container {
                                    table.table {
                                        thead {
                                            tr {
                                                th {
                                                    &:first-child {
                                                        border-radius: 0 @roundedCorners @roundedCorners 0;
                                                    }
                                                    &:last-child {
                                                        border-radius: @roundedCorners 0 0 @roundedCorners;
                                                    }
                                                }
                                                &.filters {
                                                    td {
                                                        select.form-control {
                                                            background-position: left 12px center;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                .table-paging-container {
                                    ul.pagination {
                                        li {
                                            &:first-child {
                                                a::before {
                                                    content: "\F105" !important;
                                                }
                                            }
                                            &:last-child {
                                                a::before {
                                                    content: "\F104" !important;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            .new-formio-part-b-history {
                                .panel-heading {
                                    .restart-process-box {
                                        select.form-control {
                                            background-position: left 7px center;
                                        }
                                    }
                                }
                            }
                            .new-formio-part-b {
                                .formio-component-tabs {
                                    .carousel {
                                        .carousel-inner {
                                            .carousel-item {
                                                .documents-validator {
                                                    .tab-description-back-to-list {
                                                        i {
                                                            transform: rotate(180deg);
                                                        }
                                                    }
                                                    .carousel-control {
                                                        span {
                                                            transform: rotate(180deg);
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    .validator-radio label[for*="-no"] {
                                        border-radius: @roundedCorners 0 0 @roundedCorners;
                                    }
                                    .validator-radio label[for*="-yes"] {
                                        border-radius: 0 @roundedCorners @roundedCorners 0;
                                    }
                                }
                            }
                        }
                    }
                }
                @media (min-width: 992px) {
                    :where(.col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12) {
                        &:not(td) {
                            float: right;
                        }
                    }
                }
                #rolereview {
                    .formio-component-tabs {
                        .card-header {
                            & + .card-body {
                                &.tab-pane.active {
                                    border-radius: @roundedCorners 0 @roundedCorners @roundedCorners !important;
                                }
                            }
                        }
                    }
                }
                .row.formio-component.formio-component-columns.readonly-form-columns {
                    :is(.choices__list--single, .choices__item) {
                        padding-inline: 0;
                    }
                    .input-value {
                        &:only-child {
                            display: flex;
                        }
                    }
                    p:has(.input-prefix) {
                        display: flex;
                    }
                    &.type-number {
                        > div {
                            &.col-md-2 {
                                &:first-of-type {
                                    & + div {
                                        .formio-component-htmlelement {
                                            direction: ltr;
                                            text-align: end;
                                        }
                                    }
                                }
                            }
                        }
                        .input-value {
                            &:only-child {
                                justify-content: end;
                            }
                        }
                        p:has(.input-prefix) {
                            justify-content: end;
                        }
                    }
                    &.type-editgrid {
                        &.type-number {
                            direction: ltr;

                            p[ref="html"] {
                                justify-content: end;
                            }
                        }
                    }
                    &.type-htmlelement {
                        .type-number {
                            &:is([class*="price_"], [class*="total"]) {
                                p {
                                    direction: ltr;
                                    justify-content: flex-end;
                                }
                            }
                        }
                    }
                    .formio-component-htmlelement {
                        &.type-number {
                            direction: ltr;

                            p[ref="html"] {
                                justify-content: end !important;
                                display: flex;
                            }
                            &:is([class*="formio-component-price_"], [class*="formio-component-totalCost"], [class*="formio-component-totalPrice"]) {
                                direction: rtl;

                                p[ref="html"] {
                                    justify-content: start;
                                }
                            }
                        }
                    }
                }
                .popover {
                    text-align: start;

                    &.top > .arrow {
                        margin-left: initial;
                        left: initial;
                        inset-inline-start: calc(50% - 11px);
    
                        &::after {
                            inset-inline-start: calc(50% - 10px);
                        }
                    }
                }
                .search-field {
                    background-position: center left 10px;
                }
                /*.daterangepicker.visible-calendars {
                    .calendar {
                        .calendar-table {
                            table {
                                th {
                                    &:is(.prev, .next) {
                                        i {
                                            transform: rotate(180deg);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }*/
                @media (min-width: 730px) {
                    .daterangepicker .ranges {
                        float: right;
                    }
                }
                .daterangepicker.show-ranges.ltr .drp-calendar.left {
                    border-left: none;
                }
                .daterangepicker.show-ranges.ltr .drp-calendar.right {
                    border-right: 1px solid #ddd;
                }
                .vnotify-container {
                    &.vn-bottom-right {
                        left: 16px;
                        right: initial;

                        .vnotify-text {
                            border-radius: calc(@roundedCorners - 1px) 0 0 calc(@roundedCorners - 1px);
                        }

                        .vnotify-item {
                            .vn-close {
                                left: 4px;
                                right: auto;
                            }
                        }
                    }
                }
                #base #content .section-body .contain-lg :is(#formio-form-wizard,#form-type-role,#formio-form-role):not(.new-formio-part-b) .formio-form .formio-component :where(label,label.col-form-label) i.fa-question-circle {
                    margin-inline-end: -16px;
                    transform: scaleX(-1) translateX(20px);
                }
                select:is(.form-control, .flatpickr-monthDropdown-months, .flatpickr-monthDropdown-months:hover) {
                    background-position: left 12px center;
                }

                .files-header {
                    transform: scaleX(-1);
                    & > * {
                        transform: scaleX(-1);
                    }
                }
                :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page) {
                    &:not(.new-formio-part-b) {
                        .formio-form {
                            .formio-component {
                                &:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address):not(.value-label-style) {
                                    &.has-suffix:not(.has-prefix) {
                                        .input-group > input.form-control {
                                            border-top-right-radius: @roundedCorners !important;
                                            border-bottom-right-radius: @roundedCorners !important;
                                            border-top-left-radius: 0px !important;
                                            border-bottom-left-radius: 0px !important;
                                            border-inline-start: none !important;
                                            border-inline-end: solid 1px var(--fields-border-color, var(--color-gray-300)) !important;

                                            &:focus {
                                                border-color: var(--color-gray-400) !important;
                                            }
                                        }
                                    }
                                    &.has-prefix:not(.has-suffix) {
                                        .input-group > input.form-control {
                                            border-top-left-radius: @roundedCorners !important;
                                            border-bottom-left-radius: @roundedCorners !important;
                                            border-inline-start: none;
                                            border-inline-end: solid 1px var(--fields-border-color, var(--color-gray-300)) !important;
                                            border-top-right-radius: 0px !important;
                                            border-bottom-right-radius: 0px !important;
    
                                            &:focus {
                                                border-color: var(--color-gray-400) !important;
                                            }
                                        }
                                        .input-group-prepend {
                                            border-radius: 0px @roundedCorners @roundedCorners 0px !important;
                                        }
                                        &.formio-component-number {
                                            .input-group > input.form-control {
                                                border-inline-start: solid 1px var(--fields-border-color, var(--color-gray-300)) !important;
                                                border-inline-end: none !important;
                                            }
                                        }
                                    }
                                    &.has-prefix {
                                        .input-group > .input-group-prepend {
                                            border-radius: 0px @roundedCorners @roundedCorners 0px !important;
                                        }
                                    }
                                    &.has-suffix {
                                        &.formio-component-label-hidden.formio-component-number {
                                            &.required {
                                                .input-group-append {
                                                    &::before {
                                                        inset-inline-start: initial !important;
                                                        transform: translateX(16px);
                                                    }
                                                }
                                            }
                                        }
                                        .input-group > .input-group-append {
                                            border-radius: @roundedCorners 0 0 @roundedCorners !important;
                                        }
                                    }
                                    &.has-suffix.has-prefix {
                                        .input-group > input.form-control {
                                            border-top-right-radius: 0px !important;
                                            border-bottom-right-radius: 0px !important;
                                            border-top-left-radius: 0px !important;
                                            border-bottom-left-radius: 0px !important;
                                            border-inline-start: none !important;
                                            border-inline-end: none !important;
                                        }
                                        &.formio-component-label-hidden.formio-component-number {
                                            &.required {
                                                .input-group-prepend {
                                                    &::before {
                                                        display: none !important;
                                                    }
                                                }
                                                .input-group-append {
                                                    &::before {
                                                        content: " " !important;
                                                        display: inline-block !important;
                                                        background-color: @errorColor70;
                                                        vertical-align: unset;
                                                        width: 6px;
                                                        height: 6px;
                                                        border-radius: 5px;
                                                        position: absolute;
                                                        top: calc(50% - 3px);
                                                        inset-inline-end: initial;
                                                        transform: translateX(16px);
                                                        min-width: 6px;
                                                        z-index: 9;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .formio-component-tabs > .card.card-vertical > .card-header {
                    &.collapsed {
                        .tab-expand-collapse-button {
                            --fontawesome-icon-content: "\f053";
                        }
                    }
                    .tab-expand-collapse-button {
                        --fontawesome-icon-content: "\f054";
                    }
                }
            }
        }
    }
}